<template>
  <div class="home">
    <component-menu></component-menu>
    <div class="container-fluid">
      <div>
        <div class="container">
          <div class="bi-menu">
            <h2><strong>{{$t("lang.class.multimedia.name")}}</strong></h2>
            <ul class="bi-list">
              <li>
                <a :href="`/${locale}/videoconverter`">
                  <i class="bi bi-camera-video"></i>
                  <span class="bi-class">{{$t("lang.application.video_converter.name")}}</span>
                </a>
              </li>
              <li>
                <a :href="`/${locale}/audioconverter`">
                  <i class="bi bi-file-music"></i>
                  <span class="bi-class">{{$t("lang.application.audio_converter.name")}}</span>
                </a>
              </li>
              <li>
                <a :href="`/${locale}/imageconverter`">
                  <i class="bi bi-file-image"></i>
                  <span class="bi-class">{{$t("lang.application.image_converter.name")}}</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="bi-menu">
            <h2><strong>{{$t("lang.class.text.name")}}</strong></h2>
            <ul class="bi-list">
              <!--
              <li>
                <a :href="`/${locale}/encodetools`">
                  <i class="bi bi-arrow-left-right"></i>
                  <span class="bi-class">{{$t("lang.application.encode_tools.name")}}</span>
                </a>
              </li>
              -->
              <li>
                <a :href="`/${locale}/jsontools`">
                  <i class="bi bi-file-text"></i>
                  <span class="bi-class">{{$t("lang.application.json_tools.name")}}</span>
                </a>
              </li>
              <li>
                <a :href="`/${locale}/xmltools`">
                  <i class="bi bi-file-text"></i>
                  <span class="bi-class">{{$t("lang.application.xml_tools.name")}}</span>
                </a>
              </li>
              <li>
                <a :href="`/${locale}/yamltools`">
                  <i class="bi bi-file-text"></i>
                  <span class="bi-class">{{$t("lang.application.yaml_tools.name")}}</span>
                </a>
              </li>
              <li>
                <a :href="`/${locale}/tomltools`">
                  <i class="bi bi-file-text"></i>
                  <span class="bi-class">{{$t("lang.application.toml_tools.name")}}</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="bi-menu">
            <h2><strong>{{$t("lang.class.others.name")}}</strong></h2>
            <ul class="bi-list">
              <li>
                <a :href="`/${locale}/regextester`">
                  <i class="bi bi-eye-slash"></i>
                  <span class="bi-class">{{$t("lang.application.regex_tester.name")}}</span>
                </a>
              </li>
              <li>
                <a :href="`/${locale}/uuidgenerator`">
                  <i class="bi bi-tags"></i>
                  <span class="bi-class">{{$t("lang.application.uuid_generator.name")}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <component-footer></component-footer>
  </div>
</template>

<script>
  import Vue from 'vue';
  //import $ from 'jquery';
  export default {
    metaInfo : {
      title: `${window.application.title}`,
      meta: [
        {name: 'description', conent: `${window.application.lang.meta_description}`}
      ]
    },
    head: {
      link: [
        {rel: 'alternate', hreflang: 'en', href: 'https://ezeful.app/en'},
        {rel: 'alternate', hreflang: 'es', href: 'https://ezeful.app/es'},
        {rel: 'alternate', hreflang: 'hi', href: 'https://ezeful.app/hi'},
        {rel: 'alternate', hreflang: 'ja', href: 'https://ezeful.app/ja'},
        {rel: 'alternate', hreflang: 'zh-cn', href: 'https://ezeful.app/zh_cn'},
        {rel: 'alternate', hreflang: 'zh-tw', href: 'https://ezeful.app/zh_tw'},
      ],
      //script: [
      //  { src: window.application.adsense_script, async: true, crossorigin:"anonymous"},
      //],
    },
    data () {
      return {
        locale: window.application.locale,
        title: window.application.title,
      }
    },
    created() {
      Vue.component('component-menu', require('../components/Menu.vue').default);
      Vue.component('component-footer', require('../components/Footer.vue').default);
    },
    mounted() {
      //console.log(this.$router);
      //let self = this
    },
    updated() {
      //if (this.process.loaded === true) {
      //  $("#process-result-text").val(this.process.data.text);
      //}
    },
    methods: {

    }
  }
</script>

<style>

  .bi-menu{
    margin:0 -19px 20px -16px;
    overflow:hidden
  }

  .bi-list{
    padding-left:0;
    list-style:none
  }

  .bi-list li{
    float:left;
    width:25%;
    height:115px;
    font-size:10px;
    line-height:1.4;
    text-align:center;
    margin:8px;
    background-color:#f9f9f9
  }

  .bi-list li a {
    text-decoration: none;
    width:100%;
    height:100%;
    padding:24px;
    color:#333333;
    display:block;
  }

  .bi-list li a:hover {
    color:#fff;
    background-color:#563d7c;
  }

  .bi-list li a .bi {
    margin-top:5px;
    margin-bottom:10px;
    font-size:24px
  }

  .bi-list li a .bi-class {
    margin-top:5px;
    display:block;
    text-align:center;
    word-wrap:break-word;
  }


  /** Extra Small Screen (col-xs) ********************************************************/
  @media (max-width: 767px) {

  }

  /** Small Screen (col-sm) **************************************************************/
  @media (min-width: 768px) {
    .bi-menu{
      margin-left:0;margin-right:0
    }

    .bi-menu li{
      width:12.5%;font-size:12px
    }
  }

  /** Medium Screen (col-md) *************************************************************/
  @media (min-width: 992px) {

  }
  /** Large Screen (col-lg) **************************************************************/
  @media (min-width: 1200px) {

  }

</style>
